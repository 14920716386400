import type { ChangeEvent, ClipboardEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Input as AntdInput, Typography } from 'antd';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { isExported } from '@/shared/constants/constants';

export const EnterMnemonicPhrase: FC = () => {
  const { t } = useTranslation(['auth']);
  const { setInputPhrase } = useMnemonicPhraseStore();
  const [segments, setSegments] = useState<Array<string>>(
    Array.from({ length: 12 }).fill('') as Array<string>,
  );

  function onPaste(event: ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');
    const wordsArray = pasted.trim().split(/\s+/).slice(0, segments.length);
    if (wordsArray.length < 12) {
      setSegments([
        ...wordsArray,
        ...(Array.from({ length: 12 - wordsArray.length }).fill(
          '',
        ) as Array<string>),
      ]);
    } else {
      setSegments(wordsArray);
    }
  }

  async function onPasteClick() {
    const pasted = await navigator.clipboard.readText();
    const wordsArray = pasted.trim().split(' ').slice(0, segments.length);
    if (wordsArray.length < 12) {
      setSegments([
        ...wordsArray,
        ...(Array.from({ length: 12 - wordsArray.length }).fill(
          '',
        ) as Array<string>),
      ]);
    } else {
      setSegments(wordsArray);
    }
  }

  function update(index: number) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const enteredValue = event.target.value.trim().toLowerCase();
      const wordsArray = enteredValue.split(/\s+/);

      if (wordsArray.length === 12) {
        setSegments(wordsArray.slice(0, segments.length));
      } else {
        const word = enteredValue.replace(/[^A-Za-z]/gi, '');
        setSegments([
          ...segments.slice(0, index),
          word,
          ...segments.slice(index + 1),
        ]);
      }
    };
  }

  function getEmptyInput(target: HTMLInputElement, index: number) {
    if (target.value) {
      return null;
    } else {
      const firstEmptyInput = document.querySelector(
        `#inputMnemonic${index}`,
      ) as HTMLInputElement;
      if (firstEmptyInput) {
        if (firstEmptyInput.value) {
          return getEmptyInput(target, index + 1);
        } else {
          return firstEmptyInput.focus();
        }
      } else {
        return null;
      }
    }
  }

  useEffect(() => {
    setInputPhrase(segments.map((s: string) => s.trim()).join(' '));
  }, [segments]);

  return (
    <>
      <InputsGrid>
        {segments.map((item, index: number) => (
          <Row key={index} alignCenter gapColumn={8}>
            <Number>{index + 1}</Number>
            <Input
              id={`inputMnemonic${index + 1}`}
              data-testid={`inputMnemonic-${index + 1}`}
              size="small"
              value={item as string}
              onPaste={onPaste}
              onFocus={(e) => {
                getEmptyInput(e.target, 1);
              }}
              onInput={update(index)}
              style={{ textTransform: 'lowercase' }}
            />
          </Row>
        ))}
      </InputsGrid>
      <Row width={'100%'} alignCenter justifyContent={'center'}>
        {!isExported ? (
          <Typography.Link
            style={{ margin: '25px 0 10px', cursor: 'pointer' }}
            onClick={onPasteClick}
            underline
          >
            {t('auth:login.pasteFromClipboard')}
          </Typography.Link>
        ) : null}
      </Row>
    </>
  );
};

const InputsGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: 9px;
  column-gap: 19px;
  grid-template-columns: repeat(2, 143px);
  grid-template-rows: repeat(6, 30px);
`;

const Number = styled.span`
  width: 19px;
  font-size: 14px;
  color: ${COLORS.black};
`;

const Input = styled(AntdInput)`
  &&& {
    color: ${COLORS.colorTextBase};
    background-color: ${COLORS.white};
    min-height: 30px;
    width: 90px;
    border: 1px solid ${COLORS.colorBorder};
    box-sizing: border-box;
    outline: 0;
    font-family: 'Consolas', 'Courier', monospace;

    &:focus {
      border: 1px solid ${COLORS.colorPrimaryBorder};
    }

    &:hover {
      border: 1px solid ${COLORS.colorPrimaryBorderHover};
    }
  }
`;
